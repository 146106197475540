var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row justify-content-center align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center mb-5"
  }, [_vm.report.status === 'error' || _vm.error ? _c('div', [_vm._m(0), _c('div', [_vm._v("Please contact support.")])]) : _vm.report.status === 'preparing' ? _c('Spinner', [_vm._v("Generating report... This should only take a few minutes.")]) : _vm.report.status === 'generating' ? _c('Spinner', [_vm._v("Generating report... This should only take a few minutes.")]) : _vm.report.status === 'ready' ? _c('div', [_vm._m(1), _c('p', [_vm._v(_vm._s(_vm.report.fileName))]), _c('a', {
    staticClass: "btn btn-primary btn-lg",
    class: {
      disabled: !_vm.downloadUrl
    },
    attrs: {
      "href": _vm.downloadUrl
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-download"
  }), _vm._v(" Download")])]) : _vm._e()], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font-w600 font-size-h4 mb-2"
  }, [_c('i', {
    staticClass: "fa fa-times-circle text-danger"
  }), _vm._v(" Report failed to generate.")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font-w600 font-size-h4 mb-3"
  }, [_c('i', {
    staticClass: "fa fa-check-circle text-success mr-2"
  }), _vm._v(" Report ready!")]);
}]

export { render, staticRenderFns }